import { defineStore } from "pinia";
import axios from "axios";

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: null,
      displayName: null
    }
  },

  getters: {
    isLoggedIn: (state) => state.userName !== null,
  },

  actions: {
    fetchUserDetails: async function() {
      console.log("Fetching user details");
      return  axios.get('/api/auth/user')
        .then((response) => {
          console.log("UserStore: user details fetched successfully");
          this.userName = response.data.userName;
          this.displayName = response.data.displayName;
        })
        .catch(() => {})
    },

    login: async function(username, password) {
      let postBody = {
        username: username,
        password: password
      };
      return axios.post('/api/auth/login', postBody)
        .then((response) => {
          this.userName = response.data.userName;
          this.displayName = response.data.displayName;
          return response;
        })
        .catch(err => {
          throw err;
        });
    },

    logout: async function() {
      return axios.post('/api/auth/logout')
        .then((response) => {
          return response;
        })
        .catch(err => {
          throw err;
        })
        .finally(() => {
          this.userName = null;
          this.displayName = null;
        })
    }
  }
});